import Rest from '../Rest'

export default class ProctoringService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/proctoring';

  static build(vs: any = null, baseUrl) {
    return new this(this.resource, null, vs, baseUrl)
  }

  findConfig(id) {
    return this.get(`/config/${id}`).then(
      data => { return data }
    )
  }

  createConfig(data) {
    if ('id' in data) delete data.id
    return this.post('/config', data).then(
      (data) => {
        return data
      }
    )
  }

  editConfig(data) {
    if (!('id' in data) && !data.id) return; else {
      const id = data.id
      delete data.id
      return this.put(`/config/${id}`, data).then(
        (data) => {
          return data
        }
      )
    }
  }

  storeConfig(data) {
    if ('id' in data && data.id) {
      return this.editConfig(data)
    } else {
      return this.createConfig(data)
    }
  }

  deleteConfig(id) {
    return this.delete(`/config/${id}`).then(
      (data) => {
        return data
      }
    )
  }

  getAllSupervisor(id:Number,) {
    return this.get(`/config/${id}/supervisors`)
  }
  addSupervisor(id:Number, userIds: any) {
    return this.post(`/config/${id}/supervisors`, {
      userIds: userIds
    })
  }

  removeSupervisor(id:number, supervisorIds: any) {
    return this.put(`/config/${id}/supervisors/remove`, {
      supervisorIds: supervisorIds
    })
  }

  setSupervisorAdmin(id:number, supervisorId: any, isAdmin: Boolean) {
    return this.put(`/config/${id}/supervisors/${supervisorId}/set_admin`, {is_admin: isAdmin})
  }

  toggleConfig(id, status = null) {
    status = status && {'set': status}
    return this.post(`/config/${id}/status/toggle`, status).then(
      (data) => {
        return data
      }
    )
  }

  loadRoom(roomId: Number) {
    return this.get(`/room/${roomId}`).then(data => {
      return data
    })
  }

  loadRooms(questionnaireId: Number) {
    return this.get(`/rooms/${questionnaireId || ''}`).then(data => {
      return data
    })
  }

  superviseRoom(roomId: Number) {
    return this.post(`/room/${roomId}/supervision`).then(data => {
      return data
    })
  }

  exitRoomSupervision(roomId: Number) {
    return this.delete(`/room/${roomId}/supervision`).then(data => {
      return data
    })
  }

  loadClassroomUser(classroomUserId: Number) {
    return this.get(`/classroom_user/${classroomUserId}`).then(data => {
      return data
    })
  }

  addNewEvent(classroomUserId: Number, data: Object) {
    return this.post(`/classroom_user/${classroomUserId}/event`, data).then(data => {
      return data
    })
  }

  forceProctoringProccess(answerId: Number, proctoringClassroomUserId: Number) {
    return this.post(`/force_proctoring_proccess/${answerId}/${proctoringClassroomUserId}`)
  }

  videoInProcessing(answerId: Number, proctoringClassroomUserId: Number) {
    return this.put(`/video_in_processing/${answerId}/${proctoringClassroomUserId}`, {})
  }

  async downloadRecordingLink(questionnaireId: Number, fileName: string) {
    const urlDownload = `/recording/${questionnaireId}/download/link?file_name=${fileName}`
    const data = await this.get(urlDownload)
    return data
  }

  downloadRecording(questionnaireId: Number, fileName: string) {
    const urlDownload = `/recording/${questionnaireId}/download/?file_name=${fileName}`
    return this.download(urlDownload, fileName, '')
  }
  /*
  downloadRecording(urlDownload: string, filename: string) {
    return this.download(urlDownload, filename, '')
  }
  */

  registerAudit(classroomUserId: Number, data: Object) {
    return this.post(`/classroom_user/${classroomUserId}/audit`, data).then(data => {
      return data
    })
  }

}
